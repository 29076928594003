.App {
  background-color: #9e9e9e;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.section {
  margin-bottom: 20px;
}

main {
  margin-top: 30px;
  margin-bottom: 30px;
  background: white;
  color: black;
  padding: 40px 20px;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}

.message {
  max-width: 400px;
  font-size: 16px;
  margin: 0 auto 20px auto;
}

.qrCode {
  background: white;
  width: 200px;
  justify-content: center;
  margin: 30px auto;
}

.send {
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
}

.links {
  display: flex;
  align-items: center;
  justify-content: center;
}
.links-item {
  display: block;
  margin: 0 15px;
}
.links-item img {
  width: 50px;
}

.title {
  text-align: center;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
}

.del {
  margin-bottom: 40px;
}

.App-link {
  color: #61dafb;
}

button {
  background: #5e35b1;
  padding: 10px 12px;
  font-size: 16px;
  border: none;
  color: white;
  max-width: 230px;
  width: 280px;
  border: #ffffff;
  border-radius: 16px;
  cursor: pointer;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  position: relative;
}
button.tab {
  background: white;
  border: 3px solid #5e35b1;
  color: #5e35b1;
  border-radius: 0;
  max-width: 120px;
}

button.tab.active {
  background: #5e35b1;
  color: white;
}

button.loading {
  color: #5e35b1;
  user-select: none;
  pointer-events: none;
}
button.loading:before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 3px solid white;
  border-left: 3px solid transparent;
  position: absolute;
  left: 50%;
  top: 50%;
  animation: loop .3s linear infinite;
  transform: translate(-50%, -50%);
}

input {
  width: 230px;
  padding: 10px 12px;
  box-sizing: border-box;
  border-radius: 16px;
  border: 1px solid #5e35b1;
}

button:hover {
  opacity: .8;
}
@keyframes loop {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.field {
  margin-bottom: 10px;
}

.tabs {
  justify-content: center;
  display: flex;
  margin-bottom: 20px;
}
.tab-item {
  margin: 0 10px;
}
